import React, { Component } from 'react';

export default class YouTubeEmbed extends Component {

    render() {
        return (
            <div className="youtube-embed video-embed">
                <iframe src={`https://www.youtube.com/embed/${this.props.videoId}`} title="embedded video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        );
    }

}