import React, { Component } from 'react';
import PositionCard from '../PositionCard/PositionCard';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';

export default class PositionGrid extends Component {
    
    render() {

        let positionCards = [];
        let title = null;

        for(let position of this.props.positions) {
            positionCards.push(<PositionCard position={position} />);
        }

        if(this.props.title) {
            title = (
                <h3>{this.props.title}</h3>
            );
        }

        return (
            <div className="position-grid page-section padded">
                {title}

                <ContentGrid centerIncompleteRow={false} small={12} medium={6} large={3}>{positionCards}</ContentGrid>

            </div>
        );

    }
}