import Entity from './Entity';

export default class Menu {

    data = [];
    menus = {};

    constructor(data = []) {
        this.data = data;

        this.buildMenuTrees();
    }

    getMenuTrees = () => {
        return this.menus;
    }

    getMenuTree = (menuName) => {
        if(this.menus.hasOwnProperty(menuName)) {
            return this.menus[menuName];
        }

        return null;
    }

    buildMenuTrees = () => {
        // Get the active links
        const activeLinks = this.findLinkDataByUrl(window.location.pathname);

        // Get all the parents in the branches of the active link
        let activeLinkParents = [];

        for(let activeLink of activeLinks) {
            if(activeLink.get('parent')) {
                let currentLinkData = activeLink;
                do {
                    const parentLinkId = this.getParentLinkId(currentLinkData.get('parent'));

                    const parentLinkData = this.findLink(parentLinkId);

                    activeLinkParents.push(parentLinkId);

                    currentLinkData = parentLinkData;

                } while(currentLinkData !== null && currentLinkData instanceof Entity && currentLinkData.get('parent') !== null);
            }
        }

        // Loop through every link for every menu.
        for(let linkData of this.data) {
            // If the menu does not exist in our menus object, create it.
            if(!this.menus.hasOwnProperty(linkData.get('menu_name'))) {
                this.menus[linkData.getField('menu_name')] = [];
            }

            if(this.getLinkUrl(linkData.data) === window.location.pathname) {
                linkData.data.isActiveLink = true;
                linkData.data.isActiveBranch = true;
            } else {
                linkData.data.isActiveLink = false;
                linkData.data.isActiveBranch = activeLinkParents.includes(linkData.get('id'));
            }

            // This link may already exist depending on if it is a parent of another link.
            if(this.linkExists(this.menus[linkData.get('menu_name')], linkData.get('id'))) {
                continue;
            } else {
                // Create the link when it does not already exist.
                this.createLink(linkData.data);
            }

        }
    } 

    createLink = (linkData) => {

        // Does this link have a parent?
        if(linkData.attributes.parent !== null) {

            // This link has a parent, so we have to deal with that first.
            const parentId = this.getParentLinkId(linkData.attributes.parent);

            // Does the parent link already exist in the menu?
            if(this.linkExists(this.menus[linkData.attributes.menu_name], parentId) === false) {

                // It does not, so we need to create the parent link first.
                const parentLinkData = this.findLinkData(parentId).data;

                this.createLink(parentLinkData);
            } else {
                // It does, so we just need to create the new link as a child of the parent.
                const parentLink = this.findLink(this.menus[linkData.attributes.menu_name], parentId);

                parentLink.children.push(this.buildLink(linkData));
            }
        } else {
            // This link has no parent.
            this.menus[linkData.attributes.menu_name].push(this.buildLink(linkData));
        }
    }

    buildLink = (linkData) => {
        const link = {
            id: linkData.id,
            url: this.getLinkUrl(linkData),
            weight: linkData.attributes.weight,
            parent: linkData.attributes.parent,
            title: linkData.attributes.title,
            enabled: linkData.attributes.enabled,
            children: [],
            isActiveLink: linkData.isActiveLink,
            isActiveBranch: linkData.isActiveBranch
        };

        // Returns a link object.
        return link;
    }

    findLink = (items, linkId) => {
        // Returns null if the linkId is not found in the menu structure.
        let result = null;

        for(let i = 0; i < items.length; i++) {
            // Is this the link we're looking for? If so, exit this loop.
            if(items[i].id === linkId) {
                result = items[i];
                break;
            } else if(items[i].children.length > 0) {
                // Otherwise, look recursively in the link's children.
                result = this.findLink(items[i].children, linkId);
                if(result) {
                    break;
                }
            }
        }

        // Return the link or null.
        return result;
    }

    findLinkData = (linkId) => {
        // Return the link object from the jsonapi response.
        return this.data.find((element) => element.data.id === linkId);
    }

    getParentLinkId = (parentString) => {
        // Return the UUID from the parent link property in the jsonapi response.
        return parentString.split(':').pop();
    }

    linkExists = (items, linkId) => {
        // Find the link or get back null and return as boolean.
        const link = this.findLink(items, linkId);

        if(link) {
            return true;
        }

        return false;
    }

    getLinkUrl = (linkData) => {
        // Convert Drupal uri's to urls for link href's.
        const url = linkData.attributes.link.uri.replace("internal:", "").replace("entity:", "");

        return url;
    }

    findLinkDataByUrl = (url) => {
        // Return the link object from the jsonapi response.
        return this.data.filter((element) => this.getLinkUrl(element.data) === url);
    }

}