import React, {Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Text from '../../../atoms/Text/Text';

import './AccordionItem.css';

export default class AccordionItem extends Component {

    state = {
        expanded: false
    };

    render = () => {

        let icon = faCaretRight;
        let body = null;
        if(this.state.expanded) {
            icon = faCaretDown;
            body = <Text text={this.props.item.get('field_text').get('field_body').processed} collapse={true} />;
        }

        return(
            <div className="accordion-item">
                <h3 onClick={this.toggle}><FontAwesomeIcon icon={icon} /> {this.props.item.get('field_title')}</h3>
                {body}
            </div>
        )
    }

    toggle = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }

}