import React, { Component } from 'react';
import VimeoEmbed from '../VimeoEmbed/VimeoEmbed';
import YouTubeEmbed from '../YouTubeEmbed/YouTubeEmbed';

import './RemoteVideo.css';

export default class RemoteVideo extends Component {

    render() {
        const type = this.getVideoType();

        const id = this.getVideoId();

        let component = <></>;

        if(type === 'vimeo') {
            component = <VimeoEmbed videoId={id} />;
        }

        if(type === 'youtube') {
            component = <YouTubeEmbed videoId={id} />;
        }

        return (
            <div className="remote-video">
                {component}
            </div>
        );
    }

    getVideoType = () => {
        if(this.props.url.includes('youtu')) {
            return 'youtube';
        }

        if(this.props.url.includes('vimeo')) {
            return 'vimeo';
        }

        return null;
    }

    getVideoId = () => {
        return this.props.url.split('/').pop().split('=').pop();
    }

}
