import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Menu from '../../menu/Menu/Menu';

import './Footer.css';

export default class Footer extends Component {

    render() {

        const year = new Date().getFullYear();

        return (
            <footer className="page-footer">
                <div className="container">
                    <div className="row footer-content">
                        <div className="col-12">
                            <Image src="/images/logo-white.svg" alt="Cousins Logo" />
                            <div className="copyright">
                                © {year} Cousins Properties Incorporated. All rights reserved.
                            </div>
                            <div className="copyright-menu">
                                <Menu depth={1} maxItems={2} menu={this.props.menu} menuName="copyright" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}