import React from 'react';
// import ContentPage from '../pages/ContentPage/ContentPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import PropertyDetailPage from '../pages/PropertyDetailPage/PropertyDetailPage';
import Page from '../pages/Page/Page';

export default class ComponentMap {

    static map = [
        {
            entityType: 'node',
            entityBundle: [
                'landing_page'
            ],
            component: <LandingPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'property'
            ],
            component: <PropertyDetailPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'page'
            ],
            component: <Page />
        }
    ];
}
