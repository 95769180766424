import React, { Component } from 'react';
import BuildingGridItem from '../../components/content/building/BuildingGridItem/BuildingGridItem';

import './PropertyDetailPage.css';
import Include from '../../drupal/Include';

export default class PropertyDetailPage extends Component {

    render() {
        const title = this.props.property.get('title');
        let content = [];

        for(const building of this.props.property.get('field_buildings')) {
            if(building.get('field_outlet') instanceof Include && building.get('field_outlet').get('status') === true) {
                content.push(<BuildingGridItem building={building} />);
            }
        }
        return (
            <div className="property-detail-page">
              <div className="content-wrapper container">
                <div className="row">
                  <div className="page-content col-12">
                    <h2>Please select a building below:</h2>
                    <div className="buildings">
                        {content}
                    </div>
                  </div>
                </div>
      
              </div>
            </div>
        );
      
    }

}