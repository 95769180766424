import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faDumbbell, faHome, faHotel, faMapPin, faUtensils, faSubway, faParking, faPlane, faDollarSign, faRunning, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import MapPopup from '../MapPopup/MapPopup';

import './LocationPin.css';

export default class LocationPin extends Component {

    state = {
        expanded: false
    };
    
    render() {
        let icon = faMapPin;
        let classes = "location-pin";

        switch (this.props.type) {
            case 'airport':
                icon = faPlane;
                classes += " secondary";
                break;
            case 'bank':
                icon = faDollarSign;
                classes += " secondary";
                break;
            case 'fitness':
                icon = faDumbbell;
                classes += " secondary";
                break;
            case 'grocery':
                icon = faShoppingCart;
                classes += " secondary";
                break;
            case 'hotel':
                icon = faHotel;
                classes += " secondary";
                break;
            case 'residential':
                icon = faHome;
                classes += " secondary";
                break;
            case 'recreational':
                icon = faRunning;
                classes += " secondary";
                break;
            case 'retail':
                icon = faTag;
                classes += " secondary";
                break;
            case 'transit':
                icon = faSubway;
                classes += " secondary";
                break;
            case 'parking':
                icon = faParking;
                classes += " secondary";
                break;
            case 'restaurant':
                icon = faUtensils;
                classes += " secondary";
                break;
            default:
                icon = faMapPin;
        }

        let label = (
            <div className="pin-text">{this.props.text}</div>
        );

        if(this.props.hideLabel) {
            label = null;
        }

        let popupContent = null;

        if(this.props.popupContent && this.state.expanded) {
            popupContent = <MapPopup handleClose={this.close} content={this.props.popupContent} />
        } 

        return (
            <div className={classes}>
                <FontAwesomeIcon onClick={this.open} icon={icon} />
                {popupContent}
                {label}
            </div>
        );
    }

    close = () => {
        this.setState({
            expanded: false
        });
    }

    open = () => {
        if(this.props.popupContent) {
            this.setState({
                expanded: true
            });
        }
    }
}