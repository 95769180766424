import React, { Component } from 'react';
import PopoverMenu from '../../menu/PopoverMenu/PopoverMenu';

import './Header.css';

export default class Header extends Component {

    render() {

        return (
            <header className="page-header">
                <PopoverMenu links={this.props.menuItems} />
            </header>
        );
    }

}