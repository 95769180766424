import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';

import './ImageTextItem.css';

export default class ImageTextItem extends Component {

  state = {
      initialized: false,
      isVisible: false
  };

  componentDidMount = () => {
      this.setState({
        initialized: true
      });
  }

  render() {

    let visibilityClass = 'initializing';
    if(this.state.isVisible) {
        visibilityClass = 'visible';
    }

    let waypoint = null;
    if(this.state.initialized) {
        waypoint = <Waypoint onEnter={this.display} />;
    }

    return (
        <div className={`paragraph image-text-item row no-gutters ${visibilityClass}`}>
            <div className="col-12 col-md-6">
                {this.props.firstColContent}
            </div>

            <div className="col-12 col-md-6">
                {this.props.secondColContent}
            </div>

            <div className="waypoint">
                {waypoint}
            </div>
        </div>
    );
  }

  display = () => {
      if(this.state.initialized) {
          this.setState({
              isVisible: true
          });
      }
  }

}