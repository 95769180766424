import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './ImageHover.css';

export default class ImageHover extends Component {

  render() {
    let title = null;
    if(this.props.title) {
        title = (
            <h2>{this.props.title}</h2>
        );
    }

    let subtitle = null;
    if(this.props.subtitle) {
        subtitle = (
            <h4>{this.props.subtitle}</h4>
        );
    }

    let caption = null;
    if(this.props.caption) {
        caption = (
            <div className="caption">
                {this.props.caption}
            </div>
        );
    }

    return (
        <div className="image-hover">
            <Image src={this.props.media.get('field_image').get('field_media_image').get('links').medium_square.href} />

            <div className="hover-content">
                {title}
                {subtitle}
                {caption}
            </div>
        </div>
    );
  }

}