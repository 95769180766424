import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Meta extends Component {

    render() {
        let meta = [];

        if(this.props.data instanceof Array) {
            let index = 0;

            for(let item of this.props.data) {

                const TagType = item.tag;

                let tag = null;

                if(item.tag === 'meta' && item.attributes.hasOwnProperty('name') && item.attributes.name === 'title') {
                    tag = (
                        <title key={index}>{item.attributes.content}</title>
                    )
                } else {

                    tag = (
                        <TagType key={index} {...item.attributes} />
                    );
                }

                meta.push(tag);

                index++;
            }

            return (
                <Helmet>
                    {meta}
                </Helmet>
            );
        }

        return null;
    }

}
