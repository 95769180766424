import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './PositionCard.css';

export default class PositionCard extends Component {
    
    render() {

        const person = this.props.position.get('field_person');

        let image = null;

        if(this.props.position.get('field_show_image') && person.get('field_image')) {
            const personImageMedia = person.get('field_image');
            const personImageFile = personImageMedia.get('field_media_image');

            image = (
                <div className="person-image">
                    <Image src={personImageFile.get('links').large_square.href} alt={personImageMedia.get('field_media_image').alt} />
                </div>
            );
        }

        let email = null;
        if(this.props.position.get('field_show_email')) {
            const emailHref = 'mailto:' + person.get('field_email');
            email = (
                <div className="email">
                    <a href={emailHref}>{person.get('field_email')}</a>
                </div>
            );
        }

        const name = (
            <h3 className="person-name">{person.get('title')}</h3>
        );

        let phone = null;
        if(this.props.position.get('field_show_phone') && person.get('field_phone')) {
            phone = (
                <div className="contact phone">{person.get('field_phone')}</div>
            );
        }

        let classes = "position-card";
        if(this.props.featured) {
            classes += " featured";
        }

        if(image) {
            return (
                <div className={classes}>
                    {image}
                    <div className="details">
                        {name}
                        <div className="job-title">{this.props.position.get('field_job_title')}</div>
                        {phone}
                        {email}
                    </div>
                </div>
            );
        }

        return (
            <div className={classes}>
                <div className="details">
                    {name}
                    <div className="job-title">{this.props.position.get('field_job_title')}</div>
                    {phone}
                    {email}
                </div>
            </div>
        );
    }

    formatBodyTeaser = (text) => {
        const length = 200;

        if(text.length > length) {
            return text.substring(0, length);
        } else {
            return text;
        }
    }

}