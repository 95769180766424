import React, { Component } from 'react';

export default class VimeoEmbed extends Component {

    render() {
        return (
            <div className="vimeo-embed video-embed">
                <iframe src={`https://player.vimeo.com/video/${this.props.videoId}`} title="embedded video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
        );
    }

}
