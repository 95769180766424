import React, { Component } from 'react';

import './Button.css';

export default class Button extends Component {

    render() {
        return (
            <a className={`button ${this.props.buttonClasses}`} {...this.props.target} href={this.props.url}>{this.props.text}</a>
        );
    }

}
