import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Menu from './drupal/Menu';
import Api from './drupal/Api';
import Connection from './drupal/Connection';
import RouteResolver from './pages/RouteResolver/RouteResolver';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import PropertyDetailPage from './pages/PropertyDetailPage/PropertyDetailPage';
import BuildingSelectHeader from './components/layout/BuildingSelectHeader/BuildingSelectHeader';

class App extends Component {

  state = {
    menuItems: {},
    drupal: null,
    api: null,
    outlet: null,
    building: null,
    property: null
  };

  constructor(props) {
    super(props);

    const drupal = new Connection();
    const api = new Api(drupal);

    this.state = {
      menuItems: {},
      drupal: drupal,
      api: api
    };
  }

  componentDidMount = async () => {
      const menuOptions = {
          'sort[menu_name][path]': 'menu_name',
          'sort[parent][path]': 'parent',
          'sort[weight][path]': 'weight',
          'sort[title][path]': 'title',
          'filter[name][path]': 'menu_name',
          'filter[name][value][1]': 'copyright',
          'filter[name][operator]': 'IN',

      };

      const menuResponse = await this.state.api.getEntities('menu_link_content', 'menu_link_content', menuOptions)
      const drupalMenus = new Menu(menuResponse.data.data);

      const options = {
        'filter[outlet][path]': 'field_buildings.field_outlet.name',
        'filter[outlet][value]': window.location.host
    };

    const properties = await this.state.api.getEntities('node', 'property', options);

    let property = null;
    if(properties?.data.hasOwnProperty('data')) {
        property = properties.data.data[0];
    }

    const building = property.get('field_buildings').find((element) => element.get('field_outlet').get('name') === window.location.host )

    this.setState({
        menuItems: building.get('field_customer_links'),
        outlet: building.get('field_outlet'),
        property: property,
        building: building
    });
  }

  componentDidUpdate = (prevProps) => {
      if(this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
  }

  render() {
    let pageClass = 'downpage';
    let isFront = false;
    let header = <Header menuItems={this.state.menuItems} />;

    if(this.props.location.pathname === '/') {
        pageClass = 'front';
        isFront = true;
    }

    if(this.props.location.pathname.startsWith('/select-building')) {
      pageClass = 'building-select';
      header = <BuildingSelectHeader property={this.state.property} />;
    }

    if(!this.state.outlet) {
      return (
        <></>
      );
    }

    return (
        <div className={ `page-wrapper ${pageClass}` }>
            <div className="page-top">
              {header}

              <Switch>
                <Route path="/select-building" exact={true} render={() => <PropertyDetailPage property={this.state.property} />} />
                <Route path="/*" render={(props) => <RouteResolver {...props} outlet={this.state.outlet} building={this.state.building} api={this.state.api} drupal={this.state.drupal} menu={this.getMenu} isFront={isFront} />} />
              </Switch>
            </div>

            <Footer menu={this.getMenu} />
        </div>
    );
  }

  getMenu = (menuName) => {
    if(this.state.menuItems.hasOwnProperty(menuName)) {
        return this.state.menuItems[menuName];
    }

    return [];
  }

}

export default withRouter(App);
