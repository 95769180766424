import React, { Component } from 'react';

import './Text.css';

export default class Text extends Component {

  render() {

    const html = {
      __html: this.props.text
    };

    let sectionClasses = 'page-section padded';
    if(this.props.collapse) {
      sectionClasses = '';
    }

    return (
      <div className={`text paragraph editor-body ${sectionClasses}`} dangerouslySetInnerHTML={html} />
    );
  }

}