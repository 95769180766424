import React, { Component } from "react";

import './BuildingSelectHeader.css';

export default class BuildingSelectHeader extends Component {

    render = () => {
        return(
            <header className="building-select-header">
                <div className="container">
                    <h1>{this.props.property.get('title')}</h1>
                </div>
            </header>
        );
    }

}