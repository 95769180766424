import React, {Component} from 'react';
import Text from '../../../atoms/Text/Text';
import LocalVideo from '../../media/LocalVideo/LocalVideo';
import Connection from '../../../../drupal/Connection';
import { Image } from 'react-bootstrap';

import './MediaText.css';
import RemoteVideo from '../../media/RemoteVideo/RemoteVideo';

export default class MediaText extends Component  {

    render = () => {

        const mediaEntity = this.props.item.get('field_media');

        let media = null;
        switch(mediaEntity.get('type')) {
            case 'media--image':
                media = <Image src={mediaEntity.get('field_media_image').get('links').large.href} />
                break;
            case 'media--local_video':
                media = <LocalVideo url={this.props.drupal.getBaseUrl() + mediaEntity.get('field_media_file').get('uri').url} autoPlay={false} />
                break;
            case 'media--video':
                media = <RemoteVideo url={mediaEntity.get('field_media_oembed_video')} />
                break;
        }

        let theme = 'default';
        if(this.props.item.get('field_theme')) {
            theme = this.props.item.get('field_theme');
        }

        if(this.props.item.get('field_layout') && this.props.item.get('field_layout') === 'media_first') {
            return(
                <div className="paragraph page-section padded">
                    <div className={`media-text theme-${theme}`}>
                        <div className="row">
                            <div className={`col-12 col-lg-6`}>
                                {media}
                            </div>

                            <div className={`col-12 col-lg-6 text-container`}>
                                <Text text={this.props.item.get('field_text')?.get('field_body').processed} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="paragraph page-section padded">
                <div className={`media-text theme-${theme}`}>
                    <div className="row">
                        <div className={`col-12 col-lg-6 text-container`}>
                            <Text text={this.props.item.get('field_text')?.get('field_body').processed} />
                        </div>

                        <div className={`col-12 col-lg-6`}>
                            {media}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}