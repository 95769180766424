import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import '../../paragraphs/HeroItem/HeroItem.css';
import './DefaultHeroItem.css';

export default class DefaultHeroItem extends Component {

    render() {

        let inlineStyle = {};
        let longText = null;
        let link = null;
        let video = null;

        if(this.props.building.get('field_main_image').get('field_media_image')) {
            inlineStyle.backgroundImage = `url('${this.props.building.get('field_main_image').get('field_media_image').get('links').hd1080.href}')`;
        } else if(this.props.building.get('field_main_image').get('field_media_file')) {
            video = (
                <video autoPlay playsInline={true} muted loop>
                    <source src={this.props.drupal.getBaseUrl() + this.props.building.get('field_main_image').get('field_media_file').get('uri').url} type="video/mp4" />
                </video>
            );
        }

        console.log()

        if(this.props.building.get('property_has_other_buildings')) {
            link = (
                <div className="link">
                    <div className="icon"><FontAwesomeIcon icon={faArrowLeft} /></div>
                    <a href="/select-building">Select another building</a>
                </div>
            );
        }

        return(
            <div className="hero-item default-hero-item large" style={inlineStyle}>
                <div className="underlay"></div>
                <div className="overlay"></div>
                {video}
                <div className="hero-item-content container">
                    <div className="hero-item-title">
                        <h1>{this.props.building.get('field_name')}</h1>
                    </div>

                    <div className="hero-item-details">
                        <h4>{this.constructAddress()}</h4>
                        {longText}
                        {link}
                    </div>
                </div>
            </div>
        );
    }

    constructAddress = () => {
        let addressParts = [];
    
        if(this.props.building.get('field_address').address_line1 !== null) {
            addressParts.push(this.props.building.get('field_address').address_line1);
        }
    
        if(this.props.building.get('field_address').address_line2) {
            addressParts.push(this.props.building.get('field_address').address_line2);
        }
    
        if(this.props.building.get('field_address').locality !== null && this.props.building.get('field_address').administrative_area !== null && this.props.building.get('field_address').postal_code !== null) {
            addressParts.push(`${this.props.building.get('field_address').locality}, ${this.props.building.get('field_address').administrative_area} ${this.props.building.get('field_address').postal_code}`);
        }
    
        return addressParts.join(', ');
      }
    
}