import React, {Component} from 'react';
import Text from '../../../atoms/Text/Text';
import Button from '../../../atoms/Button/Button';

import './MiniMenu.css';

export default class MiniMenu extends Component  {

    render = () => {
        let colSizeClass = null;
        let links = [];
        let text = null;

        if(this.props.text || this.props.headline) {
            colSizeClass = 'col-lg-6';

            let headline = null;

            if(this.props.headline) {
                headline = (
                    <h3>{this.props.headline}</h3>
                );
            }

            let body = null;
            
            if(this.props.text) {
                body = <Text text={this.props.text} collapse={true} />;
            }

            text = (
                <div className={`text-container ${colSizeClass}`}>
                    <div className="content">
                        <div className="active-indicator"></div>
                        {headline}
                        {body}
                    </div>
                </div>
            );
        }

        for(const linkIndex in this.props.links) {
            const renderedLink = (
                <div key={`mini-menu-${linkIndex}`} className="link">
                    <Button url={this.getLinkUrl(this.props.links[linkIndex].uri)} text={this.props.links[linkIndex].title} />
                </div>
            );

            links.push(renderedLink);
        }


        let title = null;
        if(this.props.title) {
            title = (
                <div className="title">
                    <h3>{this.props.title}</h3>
                </div>
            );
        }

        return (
            <div className="mini-menu paragraph page-section padded">
                <div className="row">
                    {text}

                    <div className={`col-12 ${colSizeClass}`}>
                        <div className="content">
                            {title}

                            <div className="links">
                                {links}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getLinkUrl = (uri) => {
        const url = uri.replace("internal:", "").replace("entity:", "");

        return url;
    }

}