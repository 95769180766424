import React, { Component } from 'react';

import './BuildingFact.css';

export default class BuildingFact extends Component {

    render() {

        if(this.props.isGridItem) {
            return (
                <div className="building-fact grid">
                    <div className="label">{this.props.label}</div>
                    <div className="value">{this.valueFormatter(this.props.value)}</div>
                </div>
            )
        }

        return (
            <div className="building-fact">
                <div className="value">{this.props.value}</div>
                <div className="label">{this.valueFormatter(this.props.label)}</div>
            </div>
        );
    }

    valueFormatter(val) {
        // Watch for phone numbers. Checking for 10 digits.  Maybe too vague.
        const match = val.match(/\d/g);

        if (match && match.length===10) {
            
          const tel = "tel:" + val.match(/\d/g).join('');
            
            return (
                <a href={tel}>{val}</a>
            );
            
        }
        
        return val;
    }

}