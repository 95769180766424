import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationPin from '../LocationPin/LocationPin';

import './GoogleMap.css';

export default class GoogleMap extends Component {
    
    render() {
        const places = this.props.places;
        const styles = this.getStyle();
        const apiKey = this.props.outlet.get('field_google_maps_api_key');
        
        let lats = [];
        let lngs = [];

        let pins = [];
        for(let place of places) {
            pins.push(<LocationPin key={place.id} lat={place.lat} lng={place.lng} hideLabel={place.hideLabel} text={place.name} type={place.type} popupContent={place.popupContent} />);
            lats.push(place.lat);
            lngs.push(place.lng);
        }

        const center = {
            lat: this.avg(lats),
            lng: this.avg(lngs)
        };

        // Google hides the control positioning constants for the zoom controls. They are:
        // TOP_LEFT: 1,
        // TOP_CENTER: 2,
        // TOP: 2,
        // TOP_RIGHT: 3,
        // LEFT_CENTER: 4,
        // LEFT_TOP: 5,
        // LEFT: 5,
        // LEFT_BOTTOM: 6,
        // RIGHT_TOP: 7,
        // RIGHT: 7,
        // RIGHT_CENTER: 8,
        // RIGHT_BOTTOM: 9,
        // BOTTOM_LEFT: 10,
        // BOTTOM_CENTER: 11,
        // BOTTOM: 11,
        // BOTTOM_RIGHT: 12,
        // CENTER: 13

        const options = {
            styles: styles,
            zoomControlOptions: {
              position: 5
            }
        };

        return (
            <div className="google-map">

                <GoogleMapReact bootstrapURLKeys={{key: apiKey}} defaultCenter={center} defaultZoom={this.props.defaultZoom} options={options}>
                    {pins}
                </GoogleMapReact>

            </div>
        );
    }

    buildLocationPins = (place) => {
        const location = <LocationPin lat={place.lat} lng={place.lng} hideLabel={place.hideLabel} text={place.name} type={place.type} />

        return location;
    }

    getStyle = () => {
      return [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.attraction",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.government",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.medical",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "poi.school",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.sports_complex",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ];
    }

    avg = (values) => {
        let avg = 0;
        let total = 0;

        if(values.length > 0) {
            for(let value of values) {
                total += value;
            }
    
            avg = total / values.length;
        }

        return avg;
    }
}