import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './BuildingGridItem.css';

export default class BuildingGridItem extends Component {

    render() {

        const linkUrl = `https://${this.props.building.get('field_outlet')?.get('name')}`;
        const address = this.constructAddress();

        return (
            <div className="building-grid-item">
                <a target="_blank" rel="noreferrer noopener" href={linkUrl}>
                    <h3>{this.props.building.get('field_name')}</h3>

                    <div className="address">
                        {address}
                    </div>

                </a>
            </div>
        );

    }

    constructAddress = () => {
        let addressParts = [];

        if(this.props.building.get('field_address').address_line1 !== null) {
            addressParts.push(<div key="address-line1" className="address-line1">{this.props.building.get('field_address').address_line1}</div>);
        }

        if(this.props.building.get('field_address').address_line2 !== null) {
            addressParts.push(<div key="address-line2" className="address-line2">{this.props.building.get('field_address').address_line2}</div>);
        }

        if(this.props.building.get('field_address').locality !== null && this.props.building.get('field_address').administrative_area !== null && this.props.building.get('field_address').postal_code !== null) {
            addressParts.push(<div key="city-state-zip" className="city-state-zip">{this.props.building.get('field_address').locality}, {this.props.building.get('field_address').administrative_area} {this.props.building.get('field_address').postal_code}</div>);
        }

        return addressParts;
    }
}