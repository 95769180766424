import React, { Component } from 'react';
import AccordionItem from '../AccordionItem/AccordionItem';

export default class Accordion extends Component {

    render = () => {

        const items = this.props.items.map((item) => {
            return <AccordionItem id={item.get('id')} item={item} />
        });

        return(
            <div className="accordion page-section padded">
                {items}
            </div>
        );
    }

}