import React, { Component } from 'react';
import MenuLink from '../MenuLink/MenuLink';

export default class MenuBranch extends Component {

    state = {
        expanded: this.props.link.isActiveBranch
    };

    render() {
        let activeClass = 'inactive';
        if(this.props.link.isActiveBranch) {
            activeClass = 'active';
        }

        const options = {
            maxDepth: this.props.maxDepth,
            collapsible: this.props.collapsible,
            expandTriggerCollapsed: this.props.expandTriggerCollapsed,
            expandTriggerExpanded: this.props.expandTriggerExpanded
        };

        let trigger = null;
        if(this.props.link.children.length > 0 && this.props.collapsible) {
            let expandTriggerCollapsedContent = '+';
            if(this.props.expandTriggerCollapsed) {
                expandTriggerCollapsedContent = this.props.expandTriggerCollapsed;
            }

            let expandTriggerExpandedContent = expandTriggerCollapsedContent;
            if(this.props.expandTriggerExpanded) {
                expandTriggerExpandedContent = this.props.expandTriggerExpanded;
            }

            let triggerContent = expandTriggerCollapsedContent;
            if(this.state.expanded) {
                triggerContent = expandTriggerExpandedContent;
            }

            trigger = (
                <div className="expand-trigger" onClick={this.toggle}>{ triggerContent }</div>
            );
        }

        let links = ([
            <MenuLink key={this.props.link.id} linkId={this.props.link.id} depth={this.props.depth} url={this.props.link.url} text={this.props.link.title} active={this.props.link.isActiveLink} />
        ]);

        // Recursively render any children as branches, respecting the maxDepth setting.
        if((!this.props.collapsible || this.state.expanded) && (this.props.maxDepth === 0 || this.props.maxDepth > this.props.depth) && this.props.link.children.length > 0) {
            let children = [];

            for(let child of this.props.link.children) {
                children.push(<MenuBranch branchId={child.id} {...options} link={child} depth={this.props.depth + 1} />);
            }

            // Add rendered children branches to the output.
            links.push(children);
        }

        return (
            <div key={`branch-${this.props.branchId}`} className={`menu-branch ${activeClass}`}>
                { trigger }
                { links }
            </div>
        );
    }

    toggle = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }

}