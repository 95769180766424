import React, { Component } from 'react';
import ImageHover from '../ImageHover/ImageHover';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';

import './ImageCollection.css';

export default class ImageCollection extends Component {

  render() {
    let title = null;
    if(this.props.title) {
        title = (
            <h2>{this.props.title}</h2>
        );
    }

    let subtitle = null;
    if(this.props.subtitle) {
        subtitle = (
            <h4>{this.props.subtitle}</h4>
        );
    }

    let images = [];
    for(let item of this.props.items) {
        images.push(<ImageHover key={item.id} title={item.get('field_title')} subtitle={item.get('field_subtitle')} caption={item.get('field_caption')} media={item} />);
    }

    return (
        <div className="paragraph image-collection page-section padded">
            {title}
            {subtitle}
            <div className="images">
                <ContentGrid small={12} medium={6} large={3}>
                    {images}
                </ContentGrid>
            </div>
        </div>
    );
  }

}