import React, { Component } from 'react';
import LocalVideo from '../../media/LocalVideo/LocalVideo';
import RemoteVideo from '../../media/RemoteVideo/RemoteVideo';
import Connection from '../../../../drupal/Connection';

import './Video.css';

export default class Video extends Component {

  render() {
    let title = null;
    if(this.props.title) {
        title = (
            <h2>{this.props.title}</h2>
        );
    }

    let subtitle = null;
    if(this.props.subtitle) {
        subtitle = (
            <h4>{this.props.subtitle}</h4>
        );
    }

    let video = null;
    
    if(this.props.media.get('type') === 'media--local_video') {
        video = <LocalVideo url={Connection.getBaseUrl() + this.props.media.get('field_media_file').get('uri').url} />;
    }

    if(this.props.media.get('type') === 'media--video') {
        video = <RemoteVideo url={this.props.media.get('field_media_oembed_video')} />
    }

    return (
        <div className="paragraph video page-section padded">
            {title}
            {subtitle}
            {video}
        </div>
    );
  }

}