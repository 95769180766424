import React, { Component } from 'react';

export default class MenuLink extends Component {

    render() {
        let activeClass = 'inactive';

        if(this.props.active) {
            activeClass = 'active';
        }

        return (
            <div key={this.props.linkId} className={`link depth-${this.props.depth} ${activeClass}`}>
                <div className="active-indicator"></div>
                <a href={this.props.url}>{this.props.text}</a>
            </div>
        );
    }

}