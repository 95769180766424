import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import './PopoverMenu.css';

export default class PopoverMenu extends Component {

    state = {
        open: false
    }

    render = () => {
        let menu = null;
        let openClass = '';

        if(this.state.open) {
            menu = this.getLinks();
            openClass = 'open';
        }

        return (
            <div className={`popover-menu ${openClass}`}>
                <div className="trigger" onClick={this.toggle}>
                    Menu <span className="icon"><FontAwesomeIcon icon={faBars} /></span>
                </div>

                {menu}
            </div>
        );
    }

    toggle = () => {
        this.setState({
            open: !this.state.open
        });
    }

    getLinks = () => {
        let links = [];
        for(const link of this.props.links) {
            links.push(
                <div className="link">
                    <a href={this.getLinkUrl(link.uri)}>{link.title}</a>
                </div>
            );
        }

        return (
            <div className="links">
                {links}
            </div>
        );
    }

    getLinkUrl = (uri) => {
        const url = uri.replace("internal:", "").replace("entity:", "");

        return url;
    }

}