import React, { Component } from 'react';
import postscribe from 'postscribe';

import './ThirdPartyEmbed.css';

export default class ThirdPartyEmbed extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount = () => {
        const scripts = this.props.embedCode.match(/<script.*?src=["|'].*?["|'].*?<\/script>/gm);

        if(scripts instanceof Array) {
            for(const script of scripts) {
                postscribe(`#scripts-${this.props.itemId}`, script);
            }
        }
    }

    render = () => {
        const html = {
            __html: this.props.embedCode
        };
      
        return (
            <div className="third-party-embed paragraph page-section padded">
                <div id={`scripts-${this.props.itemId}`} ref={this.ref} />
                <div dangerouslySetInnerHTML={html} />
            </div>
        );
    }

}