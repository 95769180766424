import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './MapPopup.css';

export default class MapPopup extends Component {

    render() {
        if(this.props.content) {
            return (
                <div className="map-popup">
                    <div className="popup-close-trigger" ><FontAwesomeIcon onClick={this.props.handleClose} icon={faTimes} /></div>
                    {this.props.content}
                </div>
            );
        }

        return (
            <></>
        );
    }

}