import React, { Component } from 'react';
import PositionGrid from '../../position/PositionGrid/PositionGrid';

export default class Positions extends Component {

    render = () => {
        return (
            <PositionGrid positions={this.props.positions} title={this.props.title} />
        );
    }

}